<template>
  <div>
    <BackButton name="Audits" route="/audits" />
    <transition name="slide-fade">
      <div v-if="this.audit" id="findings">
        <div>
          <h2 class="page-title">
            {{ audit.audit_name }}
          </h2>
          <div
            :key="componentKey"
            id="dropdown"
            style="float: right; margin-top: 10px; margin-bottom: -15px"
          >
            <b-button variant="outline-dark" class="mr-2" @click="loadData()"
              ><b-icon-arrow-clockwise></b-icon-arrow-clockwise
            ></b-button>
            <b-dropdown
              split
              right
              text="New finding"
              class="mb-4"
              variant="outline-dark"
              @click="$bvModal.show('modal-new-finding')"
              style="float: right"
            >
              <b-dropdown-item
                @click="closeAuditClicked"
                v-if="!this.audit.audit_closed"
                >Close audit</b-dropdown-item
              >
              <b-dropdown-item
                v-b-modal.modal-reopen-audit
                v-if="this.audit.audit_closed"
                >Re-open audit...
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.modal-edit-audit @click="loadCompanies"
                >Edit audit...</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="report"
                v-if="this.audit.audit_report != null"
                >View audit report</b-dropdown-item
              >
              <b-dropdown-item href="#" v-b-modal.modal-add-audit-report>{{
                this.audit.audit_report
                  ? "Replace audit report..."
                  : "Add audit report"
              }}</b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-group id="companies" header="Companies">
                <b-dropdown-item
                  v-if="
                    audit.audit_client_company &&
                    audit.audit_client_company.company_name !==
                      'Select a company...'
                  "
                  :to="'/companies/' + audit.audit_client_company.id"
                >
                  {{ audit.audit_client_company.company_name }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    audit.audit_auditee_company &&
                    audit.audit_auditee_company.company_name !==
                      'Select a company...'
                  "
                  :to="'/companies/' + audit.audit_auditee_company.id"
                >
                  {{ audit.audit_auditee_company.company_name }}
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-item
                variant="danger"
                v-b-modal.modal-delete-audit
                class="color: red !important;"
                >Delete audit</b-dropdown-item
              >
            </b-dropdown>

            <b-modal
              id="modal-delete-audit"
              title="Delete audit"
              cancel-title="Send me an email"
              cancel-variant="outline-dark"
              @cancel="emailDeclan"
              ok-variant="dark"
              ok-title="Okay"
            >
              This doesn't do anything yet!<br /><br />For now, just blank out
              the auditee & client companies so only you can see it. Or send me
              an email.
            </b-modal>

            <b-modal
              id="modal-new-finding"
              title="New finding"
              ok-title="Save"
              @ok="addFinding"
              ok-variant="dark"
              cancel-variant="outline-dark"
            >
              <b-form @submit.stop.prevent>
                <b-form-group
                  label="Finding name"
                  label-for="findingname"
                  invalid-feedback="Finding name is required"
                >
                  <b-form-input
                    v-model="newFinding.name"
                    placeholder="Finding name"
                    id="findingname"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Reference">
                  <b-form-input
                    v-model="newFinding.reference"
                    placeholder="Finding reference"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Category/Department">
                  <b-form-input
                    v-model="newFinding.category"
                    placeholder="Finding category"
                    list="categories"
                    v-if="newFinding.category !== 'Resolve before flight'"
                  ></b-form-input>
                  <datalist id="categories">
                    <option
                      v-for="category in findingCategories"
                      :key="category.id"
                    >
                      {{ category.category_name }}
                    </option>
                  </datalist>
                  <div style="width: 100%">
                    <b-form-checkbox
                      id="rbf-checkbox"
                      v-model="newFinding.category"
                      name="rbf"
                      value="Resolve before flight"
                      unchecked-value=""
                      class="mt-2"
                      style="display: inline-block; margin-right: auto"
                    >
                      Resolve before flight
                    </b-form-checkbox>
                  </div>
                </b-form-group>
                <b-form-group label="Status" v-slot="{ ariaDescribedby }">
                  <b-form-radio
                    v-model="newFinding.isClosed"
                    :aria-describedby="ariaDescribedby"
                    name="open"
                    value="false"
                    >Open</b-form-radio
                  >
                  <b-form-radio
                    v-model="newFinding.isClosed"
                    :aria-describedby="ariaDescribedby"
                    name="closed"
                    value="true"
                    >Closed</b-form-radio
                  >
                </b-form-group>
                <b-form-group label="Due date">
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="newFinding.dueDate"
                    class="mb-2"
                  ></b-form-datepicker>
                </b-form-group>
              </b-form>
            </b-modal>

            <b-modal
              id="modal-edit-audit"
              title="Edit audit"
              ok-title="Save"
              @ok="updateAudit"
              ok-variant="dark"
              cancel-variant="outline-dark"
            >
              <b-form @submit.stop.prevent>
                <b-form-group label="Audit name">
                  <b-form-input
                    v-model="editAudit.audit_name"
                    placeholder="Audit name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Client company">
                  <b-form-select
                    v-model="editAudit.audit_client_company.id"
                    :options="companies"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label="Auditee company">
                  <b-form-select
                    v-model="editAudit.audit_auditee_company.id"
                    :options="companies"
                  ></b-form-select>
                  <b-form-checkbox
                    id="auditeecanseereportcheckbox"
                    v-model="editAudit.auditee_can_see_audit_report"
                    name="auditeecanseereport"
                    class="mt-2"
                    >Auditee can see audit report</b-form-checkbox
                  >
                </b-form-group>
                <b-form-group label="Expiry date">
                  <b-form-datepicker
                    v-model="editAudit.audit_expiry_date"
                  ></b-form-datepicker>
                </b-form-group>
                <!-- <b-form-group label="Status" v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-model="editAudit.audit_closed"
              :aria-describedby="ariaDescribedby"
              name="open"
              value="false"
              >Open</b-form-radio
            >
            <b-form-radio
              v-model="editAudit.audit_closed"
              :aria-describedby="ariaDescribedby"
              name="closed"
              value="true"
              >Closed</b-form-radio
            >
          </b-form-group> -->
              </b-form>
            </b-modal>
            <b-modal
              id="modal-close-audit"
              title="Close audit"
              ok-title="Close audit"
              ok-variant="dark"
              cancel-variant="outline-dark"
              @ok="changeAuditStatus(true)"
            >
              {{ closeAuditText }}
            </b-modal>
            <b-modal
              id="modal-reopen-audit"
              title="Re-open audit"
              ok-title="Re-open audit"
              ok-variant="danger"
              cancel-variant="outline-dark"
              @ok="changeAuditStatus(false)"
            >
              <p>Are you sure you want to re-open this audit?</p>
            </b-modal>
            <b-modal
              id="modal-add-audit-report"
              title="Upload audit report"
              ok-title="Save"
              ok-variant="dark"
              cancel-variant="outline-dark"
              @ok="uploadAuditReport"
            >
              <b-form-file
                accept=".pdf"
                v-model="files[0]"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-modal>
          </div>
          <div class="audit-details" style="display: block">
            <div style="float: left; transition: all 0.5s" class="mt-2 mb-1">
              <p class="mr-3" style="float: left">
                <b-icon
                  :icon="findingStatusIcon(this.audit.audit_closed).icon"
                  :variant="findingStatusIcon(this.audit.audit_closed).variant"
                  class="mr-1"
                ></b-icon>
                {{ audit.audit_closed ? "Audit closed " : "Audit open" }}
              </p>
              <p class="mr-3" style="float: left">
                <b-icon-circle aria-hidden="true"></b-icon-circle>
                {{ audit.closedFindings.aggregate.count }} /
                {{ audit.totalFindings.aggregate.count }} findings closed
              </p>

              <p class="mr-3" style="float: left">
                <b-icon-calendar-fill
                  aria-hidden="true"
                  style="color: dodgerblue"
                ></b-icon-calendar-fill>
                {{
                  audit.audit_expiry_date
                    ? `Expires ${this.$options.filters.formatDate(
                        audit.audit_expiry_date
                      )}`
                    : "No expiry date set"
                }}
              </p>
              <p style="float: left" class="mr-3">
                <b-icon
                  :icon="auditReportStatusIcon.icon"
                  :variant="auditReportStatusIcon.variant"
                ></b-icon>
                {{
                  audit.audit_report
                    ? "Audit report attached"
                    : "No audit report"
                }}
              </p>
              <p style="float: left" class="mr-3">
                <b-icon
                  :icon="
                    audit.auditee_can_see_audit_report
                      ? 'eye-fill'
                      : 'eye-slash-fill'
                  "
                  style="color: dodgerblue"
                  aria-hidden="true"
                ></b-icon>
                Auditee
                <u>{{
                  audit.auditee_can_see_audit_report ? "can" : "can't"
                }}</u>
                see audit report
              </p>
              <!-- <p style="float: left" class="mr-3">
              <i
                class="fa fa-building"
                style="color: dodgerblue;"
                aria-hidden="true"
              ></i>
              
              Client: <router-link
          v-b-tooltip.hover title="Client"
            style="color: #2c3e50"
            tag="a"
            v-if="audit.audit_client_company"
            :to="'/companies/' + audit.audit_client_company.id"
            >{{ audit.audit_client_company.company_name }}</router-link
          > 
            </p>
            <p style="float: left" class="mr-3">
              <i
                class="fa fa-building"
                style="color: dodgerblue;"
                aria-hidden="true"
              ></i>Auditee:
          <router-link :to="'/companies/' + audit.audit_auditee_company.id" custom v-slot="{ navigate }">
  <span @click="navigate" role="link">{{ audit.audit_auditee_company.company_name }}</span>
</router-link>
            </p> -->
            </div>
          </div>
        </div>

        <FindingsTable :findings="findings" @data-updated="loadData" />
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);
import axios from "axios";
import BackButton from "../components/BackButton.vue";
import FindingsTable from "../components/FindingsTable.vue";
export default {
  components: {
    BackButton,
    FindingsTable,
  },
  data() {
    return {
      fill: { gradient: ["red", "green", "blue"] },
      findingCategories: [],
      companies: [],
      selectedFinding: undefined,
      audit: undefined,
      auditId: undefined,
      findings: undefined,
      findingsGrouped: [],
      tableIsLoading: true,
      files: [],
      messages: [],
      newMessage: undefined,
      componentKey: 0,
      dataKey: 0,
      uploadURL: undefined,
      editAudit: {
        audit_name: undefined,
        audit_closed: undefined,
        audit_expiry_date: undefined,
        audit_auditee_company: {
          id: "00000000-0000-0000-0000-000000000000",
          company_name: undefined,
        },
        audit_client_company: {
          id: "00000000-0000-0000-0000-000000000000",
          company_name: undefined,
        },
        auditee_can_see_audit_report: undefined,
      },
      newFinding: {
        name: "",
        dueDate: undefined,
        reference: "",
        isClosed: "false",
        category: "",
        rbf: false,
      },
    };
  },
  computed: {
    auditReportStatusIcon() {
      return this.audit.audit_report
        ? {
            icon: "check-circle-fill",
            variant: "success",
          }
        : {
            icon: "exclamation-circle-fill",
            variant: "danger",
          };
    },
    closeAuditText() {
      const openFindings = this.findings.filter(
        (finding) => finding.finding_closed == false
      );
      const openFindingsCount = openFindings.length;
      return openFindingsCount === 0
        ? "All findings are closed. You're good to go!"
        : openFindingsCount === 1
        ? "There is still 1 open finding. Are you sure you want to close this audit?"
        : `There are still ${openFindingsCount} open findings. Are you sure you want to close this audit?`;
    },
  },
  methods: {
    closeAuditClicked() {
      const openFindings = this.findings.filter(
        (finding) => finding.finding_closed == false
      );
      openFindings.length === 0
        ? this.changeAuditStatus(true)
        : this.$bvModal.show("modal-close-audit");
    },
    findingStatusIcon(status) {
      return status
        ? {
            icon: "check-circle-fill",
            variant: "success",
          }
        : {
            icon: "exclamation-circle-fill",
            variant: "danger",
          };
    },
    emailDeclan() {
      window.location = "mailto:declan@sky360.com.au";
    },
    async uploadAuditReport() {
      const pdfFile = this.files[0];
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            key: "audit_reports/" + this.auditId + "/audit_report.pdf",
          },
          url: "https://api.skyswarm.sky360.com.au/v1/audit-report/upload",
        })
        .then((response) => {
          const uploadURL = response.data.uploadURL;
          const config = {
            headers: {
              "Content-Type": pdfFile.type,
            },
          };
          return axios.put(uploadURL, pdfFile, config);
        })
        .then((response) => {
          if (response.status == 200) {
            this.sendNotification(
              "success",
              "Audit report uploaded successfully! 🎉",
              `"${pdfFile.name}" was successfully uploaded.`
            );
          } else {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad happened...",
              `An error occured while trying to upload "${pdfFile.name}"`
            );
          }
          this.loadData();
          this.reloadComponent();
        });
    },
    async loadCompanies() {
      this.editAudit = { ...this.audit };
      this.editAudit.audit_auditee_company = {
        ...this.audit.audit_auditee_company,
      } ?? {
        id: null,
        company_name: null,
      };
      this.editAudit.audit_client_company = {
        ...this.audit.audit_client_company,
      } ?? {
        id: null,
        company_name: null,
      };
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `query getCompanies {
              companies {
                company_name
                id
              }
            }`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.errors) {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad has happened...",
              `An error occured loading companies".
              <br><br>
              ${response.data.errors[0].message}`
            );
            return;
          } else {
            const rawCompanies = response.data.data.companies;
            this.companies = [];

            const dropdownCompanies = rawCompanies.map(function (row) {
              return { value: row.id, text: row.company_name };
            });
            this.companies = this.companies.concat(dropdownCompanies);
          }
        });
    },
    async loadData() {
      const auditIdParam = this.$route.params.auditId;
      this.auditId = auditIdParam;
      const token = await this.$auth.getTokenSilently();
      const auditId = this.auditId;
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `query {
  findings(where: {finding_audit_id: {_eq: "${auditId}"}}) {
    id
    finding_reference
    finding_name
    finding_closed
    finding_closed_date
    finding_category
    finding_audit_id
    finding_due_date
  }
  finding_categories {
    category_name
    id
  }
  audits_by_pk(id: "${auditId}") {
    audit_name
    audit_closed
    audit_expiry_date
    auditee_can_see_audit_report
    audit_report {
      s3_key
      updated_at
    }
    audit_auditee_company {
      company_name
      id
    }
    audit_client_company {
      company_name
      id
    }
    totalFindings: audit_findings_aggregate {
      aggregate {
        count
      }
    }
    closedFindings: audit_findings_aggregate(where: {_and: {finding_closed: {_eq: true}}}) {
      aggregate {
        count
      }
    }
  }
}

`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.findingsGrouped = [];
          this.findingsFormatted = [];
          this.findings = response.data.data.findings;
          this.audit = response.data.data.audits_by_pk;
          this.findingCategories = response.data.data.finding_categories;
          this.findingsGrouped = this.findings.reduce(function (acc, item) {
            if (acc[item.finding_category]) {
              acc[item.finding_category] = [
                ...acc[item.finding_category],
                item,
              ];
            } else {
              acc[item.finding_category] = [item];
            }
            return acc;
          }, {});
          this.reloadComponent();
          this.formatFindings();
        });
    },
    formatFindings() {
      for (const [key, value] of Object.entries(this.findingsGrouped)) {
        key === "Resolve before flight"
          ? this.findingsFormatted.unshift({
              mode: "span",
              label: key,
              html: false,
              children: value,
            })
          : this.findingsFormatted.push({
              mode: "span",
              label: key,
              html: false,
              children: value,
            });
      }
    },
    reloadComponent() {
      this.componentKey += 1;
    },
    async report() {
      var windowRef = window.open();
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            key: this.audit.audit_report.s3_key,
          },
          url: "https://api.skyswarm.sky360.com.au/v1/audit-report/download",
        })
        .then((response) => {
          if (response.status === 200) {
            windowRef.location.href = response.data.downloadURL;
            windowRef.document.title = "SkySwarm – Audit report";
          } else {
            this.$vs.notification({
              flat: false,
              color: "danger",
              position: "bottom-right",
              duration: "8000",
              title: "Uh oh! Something bad has happened...",
              text: "An error occured while getting the audit report",
            });
          }
        });
    },
    async updateAudit() {
      const token = await this.$auth.getTokenSilently();
      const editAudit = this.editAudit;
      const auditId = this.auditId;
      function checkDate() {
        if (editAudit.audit_expiry_date) {
          return `, audit_expiry_date: "${editAudit.audit_expiry_date}" `;
        } else {
          return "";
        }
      }
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `mutation editAudit {
        update_audits(where: {id: {_eq: "${auditId}"}}, _set: {audit_name: "${editAudit.audit_name.trim()}", audit_auditee_company_id: "${
              editAudit.audit_auditee_company.id
            }", audit_client_company_id: "${
              editAudit.audit_client_company.id
            }", auditee_can_see_audit_report: ${
              editAudit.auditee_can_see_audit_report
            }${checkDate()}}) {
          returning {
            audit_name
          }
        }
      }`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (
            response.data.data.update_audits.returning[0].audit_name ==
            this.editAudit.audit_name
          ) {
            this.$vs.notification({
              color: "success",
              duration: "8000",
              title: "Audit updated",
              text: `"${this.audit.audit_name}" was successfully updated.`,
            });
            this.reloadComponent();
            this.loadData();
          } else {
            this.$vs.notification({
              flat: false,
              color: "danger",
              position: "bottom-right",
              duration: "none",
              title: "Uh oh! Something bad has happened...",
              text: `An error occured while trying to update this audit. If this keeps happening, contact us.`,
            });
          }
        });
    },
    async changeAuditStatus(action) {
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `mutation closeAudit {
        update_audits(where: {id: {_eq: "${this.auditId}"}}, _set: {audit_closed: ${action}}) {
          returning {
            audit_closed
            id
          }
        }
      }`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (
            action == true &&
            response.data.data.update_audits.returning[0].audit_closed == true
          ) {
            this.sendNotification(
              "success",
              "Audit closed! 🎉",
              `"${this.audit.audit_name}" was successfully closed.`
            );
            this.$confetti.start({
              particles: [
                {
                  type: "circle",
                },
                {
                  type: "rect",
                },
                {
                  type: "heart",
                },
              ],
              dropRate: 30,
            });
            setTimeout(() => {
              this.$confetti.stop();
            }, 3000);
            this.audit.audit_closed = true;
            this.reloadComponent();
            this.loadData();
          } else if (
            action == false &&
            response.data.data.update_audits.returning[0].audit_closed == false
          ) {
            this.sendNotification(
              "primary",
              "Audit re-opened",
              `"${this.audit.audit_name}" was re-opened.`
            );
            this.audit.audit_closed = false;
            this.loadData();
            this.reloadComponent();
          } else {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad has happened...",
              `An error occured while trying to update this audit. If this keeps happening, contact us.`,
              "none"
            );
          }
        });
    },
    sendNotification(color, title, text, duration) {
      this.$vs.notification({
        color: color,
        duration: duration ? duration : 8000,
        title: title,
        text: text,
      });
    },
    async addFinding() {
      const token = await this.$auth.getTokenSilently();
      const newFinding = this.newFinding;
      function checkDate() {
        if (newFinding.dueDate != undefined) {
          return `, finding_due_date: "${newFinding.dueDate}" `;
        } else {
          return "";
        }
      }
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `mutation addFinding {
  insert_findings_one(object: {finding_audit_id: "${
    this.auditId
  }"${checkDate()}, finding_category: "${
              newFinding.category
            }", finding_closed: ${newFinding.isClosed}, finding_name: "${
              newFinding.name
            }", finding_reference: "${newFinding.reference}"}) {
    finding_name
  }
}`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.errors) {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad has happened...",
              `An error occured while adding the finding "${newFinding.name}" to "${this.audit.audit_name}".
              <br><br>
              ${response.data.errors[0].message}`
            );
          }
          if (
            response.data.data.insert_findings_one.finding_name ===
            newFinding.name
          ) {
            this.sendNotification(
              "success",
              "Finding added!",
              `"${response.data.data.insert_findings_one.finding_name}" added to "${this.audit.audit_name}".`
            );
            this.loadData();
          } else {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad has happened...",
              `An error occured while adding the finding "${newFinding.name}" to "${this.audit.audit_name}".`
            );
            this.loadData();
          }
          this.newFinding.name = "";
          this.newFinding.dueDate = undefined;
          this.newFinding.reference = "";
          this.newFinding.category = "";
          this.newFinding.isClosed = false;
          this.newFinding.rbf = false;
        });
    },
  },
  created: async function () {
    this.loadData();
  },
};
</script>

<style>
.vgt-inner-wrap {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.dropdown-item:active {
  background-color: #343a40;
  color: white !important;
}
.dropdown-item:active.text-danger {
  background-color: red;
  color: white !important;
}
.audit-details .b-icon {
  margin-right: 0.25rem;
}
</style>
