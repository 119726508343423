<template>
  <div>
    <vue-good-table
      :rows="findingsGrouped"
      :columns="columns"
      styleClass="vgt-table"
      :group-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'finding_name', type: 'asc' },
      }"
      ><div slot="emptystate">No findings found</div>

      <template slot="table-header-row" slot-scope="props">
        <span
          :style="
            props.row.label === 'Resolve before flight' ? 'color: red;' : ''
          "
        >
          {{ props.row.label }}
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'finding_closed'">
          <b-icon
            :icon="findingStatusIcon(props.row.finding_closed).icon"
            :variant="props.row.finding_closed ? 'success' : 'danger'"
            class="mr-1"
          ></b-icon>
          {{
            props.row.finding_closed && props.row.finding_closed_date
              ? `Closed
                ${$options.filters.formatDate(props.row.finding_closed_date)}`
              : props.row.finding_closed
              ? "Closed"
              : "Open "
          }}
        </span>
        <span v-else-if="props.column.field == 'actions-column'">
          <div>
            <b-dropdown right variant="outline-dark" size="sm" no-caret>
              <template #button-content>
                <b-icon-three-dots />
                <span class="sr-only">Search</span>
              </template>
              <b-dropdown-item
                v-if="props.row.finding_closed"
                @click="changeFindingStatus(false, props.row)"
              >
                Re-open finding
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!props.row.finding_closed"
                @click="changeFindingStatus(true, props.row)"
              >
                Close finding
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-edit-finding
                @click="editFindingClicked(props.row)"
              >
                Edit finding
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-finding-activity
                @click="findingActivityClicked(props.row)"
              >
                Finding activity
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.modal-finding-attachments
                @click="findingAttachmentsClicked(props.row)"
              >
                Attachments
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <AttachmentsModal :finding="selectedFinding" />
    <MessagesModal :finding="selectedFinding" />
    <b-modal
      id="modal-edit-finding"
      title="Edit finding"
      ok-title="Save"
      @ok="updateFinding"
      ok-variant="dark"
      cancel-variant="outline-dark"
    >
      <b-form @submit.stop.prevent>
        <b-form-group label="Finding name" label-for="findingname">
          <b-form-input
            v-model="editFinding.finding_name"
            placeholder="Finding name"
            id="findingname"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Reference">
          <b-form-input
            v-model="editFinding.finding_reference"
            placeholder="Finding reference"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Category/Department">
          <b-form-input
            v-model="editFinding.finding_category"
            placeholder="Finding category"
            list="categories"
            v-if="editFinding.finding_category !== 'Resolve before flight'"
          ></b-form-input>

          <div style="width: 100%">
            <b-form-checkbox
              id="rbf-checkbox"
              v-model="editFinding.finding_category"
              name="rbf"
              value="Resolve before flight"
              unchecked-value=""
              class="mt-2"
              style="display: inline-block; margin-right: auto"
            >
              Resolve before flight
            </b-form-checkbox>
          </div>
        </b-form-group>
        <b-form-group label="Status" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="editFinding.finding_closed"
            :aria-describedby="ariaDescribedby"
            name="open"
            value="false"
            >Open</b-form-radio
          >
          <b-form-radio
            v-model="editFinding.finding_closed"
            :aria-describedby="ariaDescribedby"
            name="closed"
            value="true"
            >Closed</b-form-radio
          >
        </b-form-group>
        <b-form-group label="Due date">
          <b-form-datepicker
            id="example-datepicker"
            v-model="editFinding.finding_due_date"
            class="mb-2"
          ></b-form-datepicker>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import MessagesModal from "../components/MessagesModal";
import AttachmentsModal from "../components/AttachmentsModal";
export default {
  name: "FindingsTable",
  components: {
    MessagesModal,
    AttachmentsModal,
  },
  props: {
    findings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          field: "finding_name",
          label: "Name",
          sortable: false,
        },
        {
          field: "finding_reference",
          sortable: false,
          label: "Reference",
        },
        {
          field: "finding_closed",
          label: "Status",
          sortable: false,
          width: "100px",
        },
        {
          field: "finding_due_date",
          label: "Due date",
          sortable: false,
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd/MM/yyyy",
          width: "120px",
        },
        {
          field: "actions-column",
          key: "open",
          label: "",
          sortable: false,
        },
      ],
      editFinding: {
        finding_name: undefined,
        finding_closed: undefined,
        finding_due_date: undefined,
        finding_category: undefined,
        finding_reference: undefined,
      },
      selectedFinding: {},
    };
  },
  computed: {
    findingsGrouped() {
      const step1 = this.findings.reduce(function (acc, item) {
        if (acc[item.finding_category]) {
          acc[item.finding_category] = [...acc[item.finding_category], item];
        } else {
          acc[item.finding_category] = [item];
        }
        return acc;
      }, {});

      const step2 = [];

      for (const [key, value] of Object.entries(step1)) {
        if (key !== "Resolve before flight") {
          step2.push({
            mode: "span",
            label: key,
            html: false,
            children: value,
          });
        }
      }
      step2.sort();
      for (const [key, value] of Object.entries(step1)) {
        if (key === "Resolve before flight") {
          step2.unshift({
            mode: "span",
            label: key,
            html: false,
            children: value,
          });
        }
      }
      return step2;
    },
  },
  methods: {
    async updateFinding() {
      const token = await this.$auth.getTokenSilently();
      const editFinding = this.editFinding;
      function checkDate() {
        if (editFinding.finding_due_date) {
          return `, finding_due_date: "${editFinding.finding_due_date}" `;
        } else {
          return "";
        }
      }
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `mutation editFinding {
        update_findings(where: {id: {_eq: "${
          editFinding.id
        }"}}, _set: {finding_name: "${editFinding.finding_name.trim()}", finding_category: "${editFinding.finding_category.trim()}", finding_reference: "${editFinding.finding_reference.trim()}"${checkDate()}}) {
          returning {
            id
          }
        }
      }`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.$emit("data-updated");
          if (
            response.data.data.update_findings.returning[0].id ==
            this.editFinding.id
          ) {
            this.$vs.notification({
              color: "success",
              duration: "8000",
              title: "Finding updated",
              text: `"${editFinding.finding_name}" was successfully updated.`,
            });
            this.reloadComponent();
            this.loadData();
          } else {
            this.$vs.notification({
              flat: false,
              color: "danger",
              position: "bottom-right",
              duration: "none",
              title: "Uh oh! Something bad has happened...",
              text: `An error occured while trying to update this finding.`,
            });
          }
          this.loadData();
        });
    },
    findingStatusIcon(status) {
      return status
        ? {
            icon: "check-circle-fill",
            variant: "success",
          }
        : {
            icon: "exclamation-circle-fill",
            variant: "danger",
          };
    },
    async findingActivityClicked(finding) {
      this.selectedFinding = finding;
    },
    editFindingClicked(finding) {
      this.editFinding = { ...finding };
    },
    async changeFindingStatus(action, finding) {
      const token = await this.$auth.getTokenSilently();
      const date = new Date();
      const formattedDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `mutation updateFindingStatus {
  update_findings_by_pk(pk_columns: {id: "${finding.id}"}, _set: {finding_closed_date: "${formattedDate}", finding_closed: ${action}}) {
    id
    finding_closed_date
    finding_closed
  }
}

`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.$emit("data-updated");
          if (
            action == true &&
            response.data.data.update_findings_by_pk.finding_closed == true
          ) {
            this.$vs.notification({
              color: "success",
              title: "Finding closed! 🎉",
              text: `"${finding.finding_name}" was successfully closed.`,
              duration: 8000,
            });
            this.reloadComponent();
          } else if (
            action == false &&
            response.data.data.update_findings_by_pk.finding_closed == false
          ) {
            this.$vs.notification({
              color: "primary",
              title: "Finding re-opened",
              text: `"${finding.finding_name}" was re-opened.`,
              duration: 8000,
            });

            this.reloadComponent();
          } else {
            this.$vs.notification({
              color: "danger",
              title: "Uh oh! Something bad has happened...",
              text: "An error occured while trying to update this finding.",
              duration: 8000,
            });
          }
        });
    },
    async findingAttachmentsClicked(finding) {
      this.selectedFinding = finding;
    },
  },
};
</script>